import React, { useEffect, useState } from 'react';

import { Button } from '@components/Button';
import Loading from '@components/Loading';
import { ResultsList, ResultsListItem } from '@components/ResultsList';

import SubscriptionsResult from './SubscriptionsResult';

import { CreditCardCode } from '@domain/my-donations/constants';

import { useGetSubscriptionsForMember } from '@repositories/my-donations';
import { useFetchProfileDetails } from '@repositories/profile/hooks';

import { useTranslation } from '@external/react-i18next';
import { useAppConfig } from '@hooks/appConfig';

const SubscriptionsList: React.FC = () => {
  const { user } = useAppConfig();
  const individualId = user?.individualId || '';
  const [subscriptionAPIError, setSubscriptionAPIError] = useState(false);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);

  const { t } = useTranslation();
  const [
    fetchSubscriptions,
    { data: subscriptionData, error: subscriptionsError },
  ] = useGetSubscriptionsForMember();
  const {
    data: profileData,
    loading: profileLoading,
    error: profileError,
  } = useFetchProfileDetails(individualId);
  const netForumMemberId =
    profileData?.individual.riIndividualId?.toString() || '';

  useEffect(() => {
    if (netForumMemberId) {
      setSubscriptionsLoading(true);
      fetchSubscriptions({
        variables: {
          MemberID: netForumMemberId,
        },
      })
        .then(() => setSubscriptionAPIError(false))
        .catch(() => setSubscriptionAPIError(true))
        .finally(() => setSubscriptionsLoading(false));
    }
  }, [netForumMemberId, subscriptionAPIError]);

  const handleRetry = () => {
    setSubscriptionsLoading(true);
    if (netForumMemberId) {
      fetchSubscriptions({
        variables: {
          MemberID: netForumMemberId,
        },
      })
        .then(() => setSubscriptionAPIError(false))
        .catch(() => setSubscriptionAPIError(true))
        .finally(() => setSubscriptionsLoading(false));
    }
  };

  if (
    subscriptionData?.subscriptions &&
    subscriptionData?.subscriptions[0] &&
    !subscriptionData?.subscriptions[0].SubscriptionId
  ) {
    if (!subscriptionAPIError) {
      setSubscriptionAPIError(true);
      setSubscriptionsLoading(false);
    }
  }

  if (profileError || !individualId) {
    return <p>Error loading profile data.</p>;
  }

  if (profileLoading) {
    return <Loading small />;
  }

  if (!netForumMemberId) {
    return null;
  }

  const subscriptions = subscriptionData?.subscriptions;

  const hasSubscriptions =
    Array.isArray(subscriptions) && subscriptions.length > 0;

  const noSubscriptions =
    Array.isArray(subscriptions) && subscriptions.length === 0;

  return (
    <div>
      {!subscriptionsLoading &&
        hasSubscriptions &&
        !subscriptionAPIError &&
        !subscriptionsError && (
          <div className="mb-5">
            <p className="text-xs text-black mb-8 mt-2 mr-3">
              {t(
                'online-giving.my-donations-form.recurring-donations-note',
                'Note that changing the information below will not update your My Rotary profile.'
              )}
            </p>
          </div>
        )}

      {subscriptionsLoading && (
        <div data-testid="subscription-loader">
          <Loading small />
        </div>
      )}

      {!subscriptionsLoading && subscriptionAPIError && (
        <div className="mt-6 mb-6 font-bold text-black pt-4 pb-4">
          <h2 className="mb-2">
            {t('subscriptions-list.error-msg-heading', `We're sorry`)}
          </h2>
          <h3 className="mb-2">
            {t(
              'subscriptions-list.error-loading-msg',
              'Unable to load subscriptions.'
            )}
          </h3>
          <button
            type="button"
            onClick={handleRetry}
            className="bg-bright-blue-600 text-white hover:bg-dark-blue-400 focus:bg-dark-blue-400 
            active:bg-dark-blue-400 active:text-dark-blue-200 mt-2 px-4 py-2 
            font-bold uppercase justify-center text-center rounded-full"
          >
            {t('subscription-list.button-try-again', 'Try Again')}
          </button>
        </div>
      )}

      {!subscriptionsLoading &&
        noSubscriptions &&
        !subscriptionsError &&
        !subscriptionAPIError && (
          <div>
            <p>
              {t(
                'subscriptions-list.no-subscriptions-msg',
                'You do not have any active recurring donations at this time.'
              )}
            </p>
            <hr className="mb-5 mt-1 border-t-2 border-b border-gray-300" />
          </div>
        )}

      {subscriptionData?.subscriptions &&
        hasSubscriptions &&
        !subscriptionAPIError &&
        !subscriptionsLoading && (
          <ResultsList
            darkBackground
            className="-mx-8 tablet:mx-0 border-t-0"
            listId="subscriptions-results-list"
          >
            {subscriptionData?.subscriptions.map(
              item =>
                item && (
                  <ResultsListItem key={item.SubscriptionId}>
                    <SubscriptionsResult
                      SubscriptionId={item.SubscriptionId || ''}
                      FundName={item.FundName}
                      TranslatedFrequency={item.TranslatedFrequency}
                      Amount={item.Amount}
                      Frequency={item.Frequency}
                      NextChargeTimestamp={item.NextChargeTimestamp}
                      Name={
                        item.Individual?.LocalizedName
                          ? item.Individual.LocalizedName
                          : `${item.Individual?.FirstName} ${item.Individual?.LastName}`
                      }
                      PhoneNumber={item.PhoneNumber}
                      EmailAddress={item.EmailAddress}
                      Address={{
                        AddressLine1: item.Address?.AddressLine1 || '',
                        AddressLine2: item.Address?.AddressLine2 || '',
                        AddressLine3: item.Address?.AddressLine3 || '',
                        City: item.Address?.City || '',
                        State: item.Address?.State || '',
                        Country: item.Address?.Country || '',
                        PostalCode: item.Address?.PostalCode || '',
                        InternationalProvince:
                          item.Address?.InternationalProvince || '',
                      }}
                      Payment={{
                        PaymentType: item.Payment
                          ?.PaymentType as CreditCardCode,
                        MaskedCC: item.Payment?.MaskedCC || '',
                        ExpirationMonth: item.Payment?.ExpirationMonth || '',
                        ExpirationYear: item.Payment?.ExpirationYear || '',
                      }}
                      Currency={item.Currency}
                    />
                  </ResultsListItem>
                )
            )}
          </ResultsList>
        )}

      <div className="mt-2">
        <p className="mb-0 font-bold text-black text-sm">
          {t('subscription-list.add-new-label', 'Add a new recurring donation')}
        </p>
        <Button isMinWidth={false} editButton className="mt-2">
          {t('subscription-list.add-button-label', 'ADD')}
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionsList;
