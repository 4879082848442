import React, { useMemo, useState } from 'react';

import { Button } from '@components/Button';
import Icon from '@components/Icon';
import Link from '@components/Link';

import { months } from '@domain/my-donations/constants';

import { useTranslation } from '@external/react-i18next';
import { log } from '@hooks/logger';

import { LogLevel } from '@typings/graphql';

type PaymentDetails = {
  PaymentType: string;
  MaskedCC?: string | null;
  ExpirationMonth?: string | null;
  ExpirationYear?: string | null;
};

type AddressDetails = {
  AddressLine1?: string;
  AddressLine2?: string | null;
  AddressLine3?: string | null;
  City?: string | null;
  State?: string | null;
  InternationalProvince?: string | null;
  PostalCode?: string | null;
  Country?: string | null;
};

type SubscriptionsResultProps = {
  SubscriptionId: string;
  FundName?: string | null;
  TranslatedFrequency?: string | null;
  Amount?: string | null;
  Frequency?: string | null;
  NextChargeTimestamp?: string | null;
  Name?: string | null;
  PhoneNumber?: string | null;
  EmailAddress?: string | null;
  Address: AddressDetails;
  Payment: PaymentDetails;
  Currency?: string | null;
};

const formatSubscriptionAddress = (Address: AddressDetails): string => {
  const addressParts: string[] = [
    Address.AddressLine1 ?? '',
    Address.AddressLine2 ?? '',
    Address.AddressLine3 ?? '',
    `${Address.City ?? ''}, ${Address.State ??
      ''}${Address.InternationalProvince ?? ''} ${Address.PostalCode ?? ''}`,
    Address.Country ?? '',
  ];
  return addressParts.filter(Boolean).join('<br>');
};

const frequency = (
  frequencyString: string,
  nxtChargeString: string
): [string, string] => {
  const raisenowTz = 'Europe/Zurich';
  const raisenowDt = new Date(
    new Date(nxtChargeString).toLocaleString('en-US', { timeZone: raisenowTz })
  );
  const nxtChgMonth = (raisenowDt.getMonth() + 1) as keyof typeof months;
  const nxtChgYear = raisenowDt.getFullYear();

  const freqCronParts = frequencyString.split(' ');

  if (freqCronParts.length !== 5) {
    log({
      level: LogLevel.Error,
      message: `Could not parse frequency string  "${frequencyString}".`,
    });
    return ['', ''];
  }

  const freqMonth = freqCronParts[3];
  let frequencyName: string;

  if (freqMonth.includes(',')) {
    frequencyName = 'Quarterly';
  } else if (!Number.isNaN(Number(freqMonth))) {
    frequencyName = 'Annually';
  } else {
    frequencyName = 'Monthly';
  }

  return [frequencyName, `First week of ${months[nxtChgMonth]} ${nxtChgYear}`];
};

const getCreditCardName = (
  Payment: PaymentDetails,
  t: (key: string, fallback?: string) => string
): string => {
  const codeLookup: Record<PaymentDetails['PaymentType'], string> = {
    es: t('payment-method.payment-slip', 'Payment Slip'),
    ezs: t('payment-method.payment-slip', 'Payment Slip'),
    sms: t('payment-method.sms', 'SMS'),
    pfc: t('payment-method.postfinance-card', 'PostFinance Card'),
    pef: t('payment-method.postfinance-e-finance', 'PostFinance E-Finance'),
    vis: t('payment-method.visa', 'VISA'),
    eca: t('payment-method.mastercard', 'MasterCard'),
    amx: t('payment-method.american-express', 'American Express'),
    din: t(
      'payment-method.diners-club-discover-card',
      'Diners Club / Discover Card'
    ),
    dd: t('payment-method.direct-debit', 'Direct Debit'),
    pex: t('payment-method.paypal', 'PayPal'),
    pp: t('payment-method.paypal', 'PayPal'),
    twi: t('payment-method.twint', 'TWINT'),
    dib: t('payment-method.sofort', 'SOFORT'),
    eps: '',
    elv: '',
  };
  return codeLookup[Payment.PaymentType] || '';
};

const calculateExpirationNotice = (
  subExpirationYear: string | null | undefined,
  subExpirationMonth: string | null | undefined,
  currentDatetime: Date,
  t: (key: string, fallback?: string) => string
): string | null => {
  const year = subExpirationYear ? '20'.concat(subExpirationYear) : '00';
  const month = subExpirationMonth ?? '01';
  const subEndOfMonthDt = new Date(parseInt(year, 10), parseInt(month, 10) - 1);

  const subEndOfMonth = new Date(
    subEndOfMonthDt.getFullYear(),
    subEndOfMonthDt.getMonth() + 1,
    0
  ).getDate();

  const subDatetime = new Date(
    parseInt(year, 10),
    parseInt(month, 10) - 1,
    subEndOfMonth
  );

  let subExpirationNotice: string | null = null;

  if (subDatetime < currentDatetime) {
    subExpirationNotice = t('Your payment method has expired');
  }

  return subExpirationNotice;
};

const SubscriptionsResult: React.FC<SubscriptionsResultProps> = ({
  SubscriptionId,
  FundName,
  TranslatedFrequency,
  Amount,
  Frequency,
  NextChargeTimestamp,
  Name,
  PhoneNumber,
  EmailAddress,
  Address,
  Payment,
  Currency,
}) => {
  const { t } = useTranslation();
  const currentDatetime = new Date();
  const [isOpen, setIsOpen] = useState(false);
  const [calculatedFrequency, calculatedNextChargeDate] = useMemo(
    () => frequency(Frequency as string, NextChargeTimestamp as string),
    [Frequency, NextChargeTimestamp]
  );
  const subAddress = useMemo(() => formatSubscriptionAddress(Address), [
    Address,
  ]);
  const subPayment = useMemo(() => getCreditCardName(Payment, t), [Payment, t]);
  const notice = calculateExpirationNotice(
    Payment.ExpirationYear,
    Payment.ExpirationMonth,
    currentDatetime,
    t
  );

  return (
    <div data-label="SearchResult" className="w-full">
      <div className="self-stretch">
        <div className="ml-6">
          <p className="hidden">{SubscriptionId}</p>
          <div className="mb-3 font-bold text-lg-18 text-black">
            {FundName} | {calculatedFrequency} | {Amount}{' '}
            {Currency?.toUpperCase()}
          </div>
          {notice && (
            <div className="text-red-500 mb-7 flex text-sm">
              <Icon
                name="ui/icon-error"
                color="red-300"
                className="w-6 h-6 mr-2"
              />
              {notice}
            </div>
          )}
          <div className="grid grid-cols-3 gap-2">
            <div>
              <div className="mb-3 font-bold text-lg-18 text-dark-blue-600-50">
                {t('subscription-result.sub-heading-donation', 'Donation')}
              </div>
              <p className="mb-0 font-bold text-black text-sm">
                {t('subscription-result.label-support', 'Support')}
              </p>
              <p className="mb-3 text-black text-xs">{FundName}</p>
              <p className="mb-0 font-bold text-black text-sm">
                {t('subscription-result.label-frequency', 'Frequency')}
              </p>
              <p className="mb-3 text-black text-xs">{TranslatedFrequency}</p>
              <p className="mb-0 font-bold text-black text-sm">
                {t(
                  'subscription-result.label-next-charge-date',
                  'Next charge date'
                )}
              </p>
              <p className="mb-3 text-black text-xs">
                {calculatedNextChargeDate}
              </p>
              <p className="mb-0 font-bold text-black text-sm">
                {t('subscription-result.label-amount', 'Amount ')}
                {Currency && (
                  <span className="font-medium">
                    {' '}
                    ({Currency.toUpperCase()})
                  </span>
                )}
              </p>
              {Amount}
              <div className="mt-3">
                <div className="pt-6">
                  <Button isMinWidth={false} editButton>
                    {t('subscription-result.edit-button-label', 'EDIT')}
                  </Button>
                  <br />
                  <div className="mt-2">
                    <Link
                      className="link-styles-off text-dark-blue-600-60 text-xs font-bold 
                      hover:text-bright-blue-600-60 active:text-bright-blue-600-60 mr-1"
                      to="/"
                      target="_blank"
                      useExternalLink
                    >
                      {t(
                        'subscriptions-list.makeup-missed-payment-link',
                        'Make up a missed payment'
                      )}
                    </Link>
                    <span
                      className="cursor-pointer"
                      onClick={() => setIsOpen(!isOpen)}
                      data-testid="makeup-missed-payment-toggle-expand-btn"
                    >
                      <Icon
                        className="color inline-block mr-3"
                        name="ui/icon-question-mark"
                        size="20"
                        color="gray-400"
                      />
                    </span>
                    {isOpen && (
                      <div className="relative mt-1 mb-1 text-3xs leading-xs font-bold text-black p-3 border-2 border-gray-350 bg-gray-650">
                        <div className="absolute right-1 top-1">
                          <span
                            className="cursor-pointer"
                            onClick={() => setIsOpen(!isOpen)}
                            data-testid="makeup-missed-payment-toggle-close-btn"
                          >
                            <Icon
                              className="color"
                              name="ui/close"
                              size="15"
                              color="bright-blue-600"
                            />
                          </span>
                        </div>
                        {t(
                          'subscriptions-result.missed-payment-note',
                          "If you miss a scheduled donation, make a one-time gift to help you meet your annual donation goal. Use your Donor History Report to see how much you've donated."
                        )}
                      </div>
                    )}
                  </div>
                  <div>
                    <Link
                      className="link-styles-off text-dark-blue-600-60 text-xs font-bold 
                      hover:text-bright-blue-600-60 active:text-bright-blue-600-60"
                      to="/"
                      target="_blank"
                      useExternalLink
                    >
                      {t(
                        'subscriptions-result.cancel-recurring-donation-link',
                        'Cancel recurring donation'
                      )}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="mb-3 font-bold text-lg-18 text-dark-blue-600-50">
                {t(
                  'subscription-result.sub-heading-personal-info',
                  'Personal information'
                )}
              </div>
              <p className="mb-0 font-bold text-black text-sm">
                {t('subscription-result.label-name', 'Name')}
              </p>
              <p className="mb-3 text-black text-xs">{Name}</p>
              <p className="mb-0 font-bold text-black text-sm">
                {t('subscription-result.label-phone', 'Phone')}
              </p>
              <p className="mb-3 text-black text-xs">{PhoneNumber}</p>
              <p className="mb-0 font-bold text-black text-sm">
                {t('subscription-result.label-email', 'Email')}
              </p>
              <p className="mb-3 text-black text-xs">{EmailAddress}</p>
            </div>
            <div>
              <div className="mb-3 font-bold text-lg-18 text-dark-blue-600-50">
                {t(
                  'subscription-result.sub-heading-payment-details',
                  'Payment details'
                )}
              </div>
              <p className="mb-0 font-bold text-black text-sm">
                {t(
                  'subscription-result.label-payment-method',
                  'Payment method'
                )}
              </p>
              <p className="mb-3 text-black text-xs">
                {subPayment}
                {t('subscription-result.label-ending', ' ending in')}{' '}
                {(Payment.MaskedCC ?? '').slice(-4)}{' '}
              </p>
              <p className="mb-0 font-bold text-black text-sm">
                {t(
                  'subscription-result.label-expiration-date',
                  'Expiration date'
                )}
              </p>
              <p className="mb-3 text-black text-xs">{`${Payment.ExpirationMonth}/${Payment.ExpirationYear}`}</p>
              <p className="mb-0 font-bold text-black text-sm">
                {t(
                  'subscription-result.label-billing-address',
                  'Billing address'
                )}
              </p>
              <p
                className="mb-3 text-black text-xs"
                dangerouslySetInnerHTML={{ __html: subAddress }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionsResult;
